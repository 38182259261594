import { DateTime } from 'luxon'

export default [
    {
        headerName: 'First Name',
        field: 'FirstName',
        sortable: false,
    },
    {
        headerName: 'Last Name',
        field: 'LastName',
        sortable: false,
    },
    {
        headerName: 'User Name',
        field: 'Username',
        sortable: false,
    },
    {
        headerName: 'District User ID',
        field: 'DistrictUserId',
        sortable: false,
    },
    {
        headerName: 'email',
        field: 'Email',
        sortable: false,
    },
    {
        headerName: 'Sync Date',
        field: 'Timestamp',
        valueFormatter: (params) =>
            DateTime.fromISO(params.value).toFormat('MM/dd/yyyy') ?? params.value,
    },
    {
        headerName: 'Error',
        field: 'Error',
        sortable: false,
    },
]
