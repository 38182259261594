<template>
    <ag-grid-server-side
        v-if="clientId"
        :column-defs="columnDefs"
        :server-side-datasource="serverSideDatasource"
    />

    <p v-else>No client ID is associated with this district</p>
</template>

<script>
import AgGridServerSide from '@/components/table/AgGridServerSide.vue'
import ColumnDef from '@/helpers/ag-grid/columnDef/accountSyncLogs.js'
import { mapActions, mapGetters } from 'vuex'
import DataSource from '@/helpers/ag-grid/datasource/graphql'

export default {
    components: { AgGridServerSide },
    data: () => ({
        columnDefs: ColumnDef,
    }),
    computed: {
        ...mapGetters({
            getItems: 'accountSyncLog/getItems',
            clientId: 'getIlluminateClientId',
        }),
    },
    methods: {
        ...mapActions({
            get: 'accountSyncLog/get',
        }),
        serverSideDatasource: function () {
            const fetchRows = async (params) => {
                params.ClientId = this.clientId
                if (params['order[Timestamp]']) {
                    params.SortDir = params['order[Timestamp]'].toUpperCase()
                    delete params['order[Timestamp]']
                }

                await this.$store.dispatch('accountSyncLog/get', params)
            }

            return {
                getRows: DataSource.partialStore(fetchRows, 'accountSyncLog').getRows,
            }
        },
    },
}
</script>
